<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        :value="value"
        @input="$emit('input')"
      >
        <template v-slot:default="">
          <v-card>
            <v-toolbar color="primary" dark>Relátorio</v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatStartDate"
                        clearable
                        hint="Ínicio"
                        readonly
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        autocomplete="off"
                        @blur="payload.startDate = parseDate(formatStartDate)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="pt-BR"
                      :first-day-of-week="0"
                      v-model="payload.startDate"
                      @change="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatEndDate"
                        clearable
                        hint="Término"
                        readonly
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        autocomplete="off"
                        @blur="payload.endDate = parseDate(formatEndDate)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="pt-BR"
                      :first-day-of-week="0"
                      v-model="payload.endDate"
                      @change="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click.native="$emit('input')">Fechar</v-btn>
              <v-btn
                :disabled="exportBtnDisabled"
                text
                color="primary"
                @click="getReports"
                :loading="loading"
                >Exibir</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
/* eslint-disable */
import { API } from "aws-amplify";
import acceptedTermExport from "@/utils/csvExport/acceptedTermExport";
import { listDpss } from "@/graphql/queries";

export default {
  name: "BrokerModal",
  props: ["value"],
  computed: {
    formatStartDate() {
      return this.formatDate(this.payload.startDate);
    },
    formatEndDate() {
      return this.formatDate(this.payload.endDate);
    },
    exportBtnDisabled() {
      if (
        this.payload.startDate !== null &&
        this.payload.endDate !== null &&
        this.payload.startDate <= this.payload.endDate
      ) {
        return false;
      }
      return true;
    }
  },
  data: () => ({
    loading: false,
    menu1: false,
    menu2: false,
    payload: {
      startDate: null,
      endDate: null
    }
  }),

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async getReports() {
      this.$store.commit("loading", true);
      try {
        const beneficiaries = this.$store.getters.beneficiaries({ cnpj: this.cnpj });
        if (beneficiaries) return beneficiaries;

        let token;
        let allItems = [];

        do {
          const { data } = await API.graphql({
            query: listDpss,
            variables: {
              filter: {
                createdAt: {
                  ge: this.payload.startDate,
                  le: this.payload.endDate
                },
                acceptedTerm: { eq: false }
              },
              limit: 9999,
              nextToken: token
            }
          });
          const { items, nextToken } = data.listDpss;
          token = nextToken;
          allItems.push(...items);
        } while (token);
        if (allItems.length === 0) {
          this.$store.commit("showAlert", {
            type: "error",
            message: "Nenhum resultado encontrado"
          });
        } else {
          acceptedTermExport(allItems);
          this.$store.commit("showAlert", {
            type: "success",
            message: "Arquivo gerado com sucesso"
          });
        }
      } catch (error) {
        console.log(error);
        this.$store.commit("showAlert", {
          type: "error",
          message: "Não foi possivel carregar os itens da DPS"
        });
      } finally {
        this.$store.commit("loading", false);
        this.$emit("input");
      }
    }
  },
  mounted() {
    this.payload.broker = "";
  }
};
</script>
<style></style>
