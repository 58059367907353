import JsonToCsv from 'json2csv';
import downloadBlob from '../downloadBlob';

// eslint-disable-next-line import/prefer-default-export
export default (arrayItem) => {
  const csv = JsonToCsv.parse(arrayItem, {
    fields: [
      {
        label: 'Termo de Aceite',
        value: 'acceptedTerm',
      },
      {
        label: 'Data de Nascimento',
        value: 'birthDate',
      },
      'cnpj',
      'cpf',
      'cpt',
      {
        label: 'Data de Criação',
        value: 'createdAt',
      },
      'email',
      'id',
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'Sobrenome',
        value: 'lastName',
      },
      {
        label: 'Telefone',
        value: 'phone',
      },
      {
        label: 'Sexo',
        value: 'sex',
      },
    ],
  });

  downloadBlob({ type: 'text/csv', filename: 'termo_de_aceite.csv', content: csv });
  return true;
};
